<div class="main-container" *ngIf="!loadedPrebooking">
  <app-booking-stepper></app-booking-stepper>
  <div class="inner-container overflow-y mt-3">
    <div class="dm-container">
      <div class="d-flex flex-column align-items-center">
        <div class="spinner-border mb-3" style="width: 4rem; height: 4rem" role="status"></div>
        {{ "LOADING_DATA" | translate }}
      </div>
    </div>
  </div>
  <div class="inner-footer">
    <div class="dm-container">
      <button class="btn btn-primary footer-button" [disabled]="true">
        <i class="bi bi-arrow-left"></i>
        <span>{{ "STEPPER_PREV" | translate }}</span>
      </button>
      <button class="btn btn-primary footer-button" [disabled]="true">
        <i class="bi bi-arrow-right"></i>
        <span> {{ "STEPPER_NEXT" | translate }}</span>
      </button>
    </div>
  </div>
</div>
<div class="main-container" [hidden]="!loadedPrebooking">
  <app-booking-stepper></app-booking-stepper>
  <div class="inner-container overflow-y px-3 pb-4 text-break">
    <app-title-with-languages [title]="'DATA_TITLE'" [hidden]="!(bookingService.isScreenSmall$ | async) && bookingService.desktopStep == 1"></app-title-with-languages>
    <div class="dm-container">
      <form class="d-flex flex-column flex-md-row w-100 align-items-end justify-content-between">
        <div class="form-group mb-0 lg-30 w-100 flex-grow-1">
          <label>
            {{ ("PREBOOKING_SHIPMENTREFERENCE" | translate) + " *" }}
          </label>
          <input type="text" name="shipmentReference" class="form-control" [(ngModel)]="shipmentReference" [required]="true" autocomplete="off" />
        </div>
        <div class="form-group mb-0 lg-30 w-100 flex-grow-1">
          <label>
            {{ ("PREBOOKING_PIN" | translate) + " *" }}
          </label>
          <input type="text" name="pin" class="form-control" [(ngModel)]="pin" [required]="true" autocomplete="off" />
        </div>
        <button
          type="button"
          class="btn btn-primary btn-50-mobile mt-3 lg-30 w-100 flex-grow-1"
          [disabled]="!(shipmentReference && shipmentReference !== '' && pin && pin !== '')"
          (click)="loadPrebookingClick()"
        >
          {{ "PREBOOKING_LOAD" | translate }}
        </button>
      </form>

      <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
        {{ "PREBOOKING_NOTFOUND" | translate }}
      </div>

      <table class="table table-striped table-hover mt-3">
        <thead>
          <tr>
            <th>{{ "PREBOOKING_SHIPMENTREFERENCE" | translate }}</th>
            <th>{{ "PREBOOKING_PIN" | translate }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let prebook of prebookingsList; let i = index">
            <td>{{ prebook.shipmentReference }}</td>
            <td>{{ prebook.pin }}</td>
            <td>
              <button type="button" class="btn btn-primary text-white" (click)="removePrebooking(i)"><i class="bi bi-trash my-auto"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="inner-footer">
    <div class="dm-container">
      <button class="btn btn-primary footer-button" [disabled]="true">
        <i class="bi bi-arrow-left"></i>
        <span>{{ "STEPPER_PREV" | translate }}</span>
      </button>
      <button
        class="btn btn-primary footer-button"
        (click)="goNextFromPrebooking()"
        [disabled]="forcePrebooking && (!prebookingsList || (prebookingsList && prebookingsList.length == 0))"
      >
        <i class="bi bi-arrow-right"></i>
        <span> {{ "STEPPER_NEXT" | translate }}</span>
      </button>
    </div>
  </div>
</div>
