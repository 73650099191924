import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { BookingLMApiService, CustomerPlantConfigurationModelExtLMApi, DirectionLMApi, GetSlotsRequestLMApi, GetSlotsResponseLMApi, SlotLMApi } from "src/app/api";
import { dateToUtcDate, getDateInTimezone } from "src/app/services/utils";
import { BookingService } from "../../../services/booking.service";
import { SlotDateSelectionDialogComponent } from "src/app/dialogs/slot-date-selection-dialog/slot-date-selection-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EditService } from "src/app/services/edit.service";

@Component({
  selector: "app-edit-slot",
  templateUrl: "./edit-slot.component.html",
})
export class EditSlotComponent {
  loaded: boolean = false;
  configuration: CustomerPlantConfigurationModelExtLMApi | null = null;
  slots: SlotLMApi[] | null | undefined = null;
  initialSlot!: SlotLMApi;
  selectedSlot: SlotLMApi | null | undefined = null;
  currentDate: Date | undefined;
  nextDisabled: boolean = false;
  prevDisabled: boolean = true;
  error: string | undefined;

  constructor(private router: Router, private route: ActivatedRoute, private modal: NgbModal, public bookingService: BookingService, private apiService: BookingLMApiService, public editService: EditService) {
    if (!this.bookingService.data || !this.bookingService.editInitialBookingDate) {
      this.router.navigate(["."], { relativeTo: this.route.parent?.parent });
    }

    this.bookingService.setStepperActive(3);
    this.configuration = this.bookingService.warehouseConfiguration;

    this.initialSlot = {
      code: this.bookingService.data.gateCode,
      from: this.bookingService.editInitialBookingDate,
      //TODO da ottenere valore corretto
      maxDelay: 25,
    };
    //Se server comunica che non possiamo mantenere lo slot iniziale, verifichiamo che vengano toccati solo i campi opzionali
    if (!this.bookingService.checkSameSlotValid && this.bookingService.getSlots)
      this.bookingService.checkSameSlotValid = editService.sameMandatoryBooking;

    //Primo controllo per slot iniziale
    if (this.bookingService.getSlots)
      this.checkSameSlot(this.bookingService.getSlots!);
    this.bookingService.$getSlots.subscribe((getSlots) => {
      if (getSlots) {
        this.slots = getSlots.slots;
        this.bookingService.getSlots!.slots;
        this.currentDate = getSlots.date ? getDateInTimezone(new Date(getSlots.date!), this.configuration!.timezone ?? "UTC") : this.currentDate;
        this.bookingService.selectedDate = this.currentDate;
        this.prevDisabled = this.currentDate! < this.bookingService.minValidDate!;
        this.selectedSlot = this.bookingService.selectedSlot ?? bookingService.checkSameSlotValid ? this.initialSlot : null;
        this.loaded = true;
      } else {
        this.slots = null;
        if (bookingService.checkSameSlotValid) {
          this.selectedSlot = this.initialSlot;
          this.slots = [];
          this.slots = [...Array.from(new Set(this.slots).add(this.initialSlot))];
        }
        this.loaded = true;
      }
    });
  }

  prevDay = () => {
    this.currentDate?.setDate(this.currentDate.getDate() - 1);
    this.currentDate?.setHours(0);
    this.apiCall(DirectionLMApi.Backward);
  };
  nextDay = () => {
    this.currentDate?.setDate(this.currentDate.getDate() + 1);
    this.currentDate?.setHours(0);
    this.apiCall(DirectionLMApi.Forward);
  };
  refresh = () => {
    this.selectedSlot = null;
    this.bookingService.selectedSlot = null;
    this.apiCall(DirectionLMApi.Forward);
  };

  openDateDialog = () => {
    let ref = this.modal.open(SlotDateSelectionDialogComponent, { centered: true });
    ref.closed.subscribe((x) => {
      this.bookingService.selectedDate = x;
      this.apiCall(DirectionLMApi.Forward);
    });
  };
  private checkSameSlot(getSlotsResponse: GetSlotsResponseLMApi, direction?: DirectionLMApi) {
    const defaultCondition =
      // Se cerco indietro e la data selezionata è inferiore rispetto alla data della GetSlots, allora slot iniziale
      direction == "Backward" && new Date(this.bookingService.selectedDate!).getDate() < new Date(getSlotsResponse.date!).getDate() ||
      // Se cerco avanti e la data selezionata è inferiore rispetto alla data della GetSlots e la data dello slot attuale è la stessa della data selezionata, allora slot iniziale 
      direction == "Forward" && new Date(this.bookingService.selectedDate!).getDate() < new Date(getSlotsResponse.date!).getDate() && new Date(this.bookingService.selectedDate!).getDate() == new Date(this.initialSlot.from!).getDate() ||
      // Se primo caricamento della route edit-slot e la data dello slot iniziale è inferiore alla data della GetSlots, allora slot iniziale
      !direction && new Date(this.initialSlot.from!).getDate() < new Date(getSlotsResponse.date!).getDate();

    if (this.bookingService.checkSameSlotValid) {
      if (defaultCondition) {
        this.bookingService.getSlots = {
          date: new Date(this.initialSlot.from!).toISOString(),
          slots: [this.initialSlot]
        }
      }
      else
        this.bookingService.getSlots = {
          date: getSlotsResponse.date!,
          slots: new Date(this.initialSlot.from!).toLocaleDateString() == new Date(getSlotsResponse.date!).toLocaleDateString() ?
            _.uniqWith(getSlotsResponse.slots?.concat(this.initialSlot), (a, b) => a.from?.toString() == b.from?.toString()).sort((a, b) => new Date(a.from!) < new Date(b.from!) ? -1 : 1) :
            getSlotsResponse.slots
        }
    }
    // Ritorniamo risposta della GetSlots
    else {
      this.bookingService.getSlots = getSlotsResponse;
    }
  }
  apiCall = (direction: DirectionLMApi) => {
    this.loaded = false;
    let getSlotRequest: GetSlotsRequestLMApi = {
      dateUtc: dateToUtcDate(this.bookingService.selectedDate!).toISOString(),
      truckType: this.bookingService.data.truckType,
      minValidDate: this.bookingService.minValidDate?.toISOString(),
      products: this.bookingService.data.products,
      direction: direction,
      filteredCode: this.bookingService.data.code,
      useMaxSlotLimits: true
    };
    this.apiService.getSlots(this.bookingService.selectedWarehouseData?.token!, getSlotRequest).subscribe((x: GetSlotsResponseLMApi) => {
      //Controllo su slot iniziale a seguito di ricerca giorno
      this.checkSameSlot(x, direction);
    });
  };

  selectSlot = (slot: SlotLMApi) => {
    this.selectedSlot = slot;
    this.bookingService.selectedSlot = this.selectedSlot;
  };

  goBack = () => {
    this.router.navigate(["."], { relativeTo: this.route.parent?.parent });
  };
  goNext = () => {
    this.error = undefined;
    this.bookingService.selectedSlot = this.selectedSlot;
    this.router.navigate(["add-reason"], { relativeTo: this.route.parent });
  };
}
