import { Injectable } from '@angular/core';
import { BookingShipmentLMApi, ProductBookingLMApi } from '../api';
import { arraysEqual } from './utils';

type BookingShipmentMandatoryFields = Pick<BookingShipmentLMApi, 'truckType' | 'products'>;

@Injectable({
  providedIn: 'root'
})
export class EditService {

  /** Flag che viene valorizzato a true se non sono variati i campi obbligatori TruckType e Products  */
  public sameMandatoryBooking: boolean = true;
  /** Proprietà dove viene salvato il modello iniziale della prenotazione */
  public initialBookingModel!: BookingShipmentLMApi;
  constructor() {
  }

  /**
   * @description Funzione per controllare eventuali modifiche sui campi obbligatori, nello specifico TruckType e Products
   * @param initial Modello originale
   * @param final Modello modificato
   * @returns Bool con valore true se non sono state apportate modifiche ai campi obbligatori, false se sono stati modificati
   */
  compareModel = (initial: BookingShipmentMandatoryFields, final: BookingShipmentMandatoryFields) =>
    initial.truckType === final.truckType &&
      arraysEqual(initial.products!, final.products!);
}
