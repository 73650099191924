import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-prebooking-choose-email',
  template: `
    <div class="modal-header">
      <h3 class="modal-title">
        {{ title | translate }}
      </h3>
    </div>
    <div class="modal-body">
      <div>
        {{ body | translate }}
      </div>
      <select class="form-select" [(ngModel)]="mail">
        <option *ngFor="let e of emails" [value]="e">{{ e }}</option>
      </select>
    </div>
    <div class="modal-footer justify-content-center p-0">
      <button type="button" class="btn btn-primary btn-footer-mobile right" (click)="proceed()" [disabled]="mail === '' || mail === null || mail === undefined">
        {{ 'PROCEED' | translate }}
      </button>
    </div>
  `,
})
export class PrebookingChooseEmailComponent {
  title = 'CHOOSE_EMAIL';
  body = 'MULTIPLE_EMAILS_FOUND';

  @Input() emails: string[] = [];
  mail: string = '';

  constructor(public activeModal: NgbActiveModal) {}

  proceed() {
    this.activeModal.close(this.mail);
  }
}
