<div class="main-container">
  <app-booking-stepper></app-booking-stepper>
  <app-title-with-languages [title]="'DESTINATION_TITLE'"></app-title-with-languages>
  <div class="px-3 pb-3">
    <div class="dm-container" *ngIf="loaded">
      <input
        id="search-text"
        type="text"
        placeholder="{{ 'DESTINATION_SEARCH_PLACEHOLDER' | translate }}"
        class="form-control search-warehouse"
        [(ngModel)]="searchText"
        (selectItem)="selectSearch($event)"
        [ngbTypeahead]="search"
        [editable]="false"
        autofocus
      />
    </div>
    <div class="inner-container overflow-y mt-3" *ngIf="!loaded">
      <div class="d-flex flex-column align-items-center">
        <div class="spinner-border mb-3" style="width: 4rem; height: 4rem" role="status"></div>
        {{ "LOADING_DATA" | translate }}
      </div>
    </div>
  </div>
  <ngb-alert type="success" style="position: fixed; left:34vw; top: 1vh; z-index: 6; width: 22%;font-size:small;" *ngIf="bookingService.tooltipOldBooking?.visible" (closed)="bookingService.tooltipOldBooking.visible = false">{{'OLDBOOKING_BODY' | translate}} <span (click)='bookingService.goToOldBooking()' style='cursor: pointer;color: dodgerblue;font-weight: bold;'>{{'HERE' | translate}}</span></ngb-alert>
  <div
    *ngIf="loaded"
    class="inner-container flex-grow-1 m-3"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    [leafletMarkerCluster]="markerClusterData"
    [leafletMarkerClusterOptions]="markerClusterOptions!"
    (leafletMarkerClusterReady)="markerClusterReady($event)"
  ></div>
</div>
