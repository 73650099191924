import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseNewShipmentComponent } from './pages/book/base-new-shipment.component';
import { EditSlotComponent } from './pages/edit/edit-slot/edit-slot.component';
import { BookComponent } from './pages/book/book/book.component';
import { MyBookingsComponent } from './pages/my-bookings/my-bookings.component';
import { BookSlotComponent } from './pages/book/book-slot/book-slot.component';
import { ViewComponent } from './pages/view/view.component';
import { EditComponent } from './pages/edit/edit.component';
/* import { BookByPlantComponent } from "./pages/book/book-by-plant/book-by-plant.component"; */
import { BookByPlantPrebookingComponent } from './pages/book/book-by-plant-prebooking/book-by-plant-prebooking.component';
import { EditAddReasonComponent } from './pages/edit/edit-add-reason/edit-add-reason.component';
import { Page404Component } from './pages/404/404.component';
import { Page404ShipmentComponent } from './pages/404/404-shipment.component';
import { EditByPlantComponent } from './pages/edit/edit-by-plant/edit-by-plant.component';
import { BookByCustomerComponent } from './pages/book/book-by-customer/book-by-customer.component';
import { DeletedComponent } from './pages/deleted/deleted.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'book',
    pathMatch: 'full',
  },
  {
    path: 'book',
    children: [
      {
        path: '',
        component: BookComponent,
      },
      {
        path: 'new',
        children: [
          {
            path: '',
            component: BaseNewShipmentComponent,
          },
          {
            path: 'slot',
            component: BookSlotComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'bookbyplantprebooking/:plantId',
    children: [
      {
        path: '',
        component: BookByPlantPrebookingComponent,
      },
      {
        path: 'new',
        children: [
          {
            path: '',
            component: BaseNewShipmentComponent,
          },
          {
            path: 'slot',
            component: BookSlotComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'bookbyplant/:plantId',
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            component: BaseNewShipmentComponent,
          },
          {
            path: 'slot',
            component: BookSlotComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'bookbyplant/:plantId/:lang',
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            component: BaseNewShipmentComponent,
          },
          {
            path: 'slot',
            component: BookSlotComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'bookbycustomer/:customerCode',
    children: [
      {
        path: '',
        component: BookByCustomerComponent,
      },
      {
        path: 'new',
        children: [
          {
            path: '',
            component: BaseNewShipmentComponent,
          },
          {
            path: 'slot',
            component: BookSlotComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'edit/:bookingCodeCripted',
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            component: EditComponent,
          },
          {
            path: 'edit-slot',
            children: [
              {
                path: '',
                component: EditSlotComponent,
              },
              {
                path: 'add-reason',
                component: EditAddReasonComponent,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'editbyplant/:bookingCodeCripted',
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            component: EditByPlantComponent,
          },
          {
            path: 'edit-slot',
            children: [
              {
                path: '',
                component: EditSlotComponent,
              },
              {
                path: 'add-reason',
                component: EditAddReasonComponent,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'view/:bookingCodeCripted',
    component: ViewComponent,
  },
  {
    path: 'view/:bookingCodeCripted/:complete',
    component: ViewComponent,
  },
  {
    path: 'my-bookings',
    component: MyBookingsComponent,
  },
  {
    path: 'deleted',
    component: DeletedComponent,
  },
  {
    path: '404',
    component: Page404Component,
  },
  {
    path: '404-shipment',
    component: Page404ShipmentComponent,
  },
  { path: '**', pathMatch: 'full', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
