import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, combineLatest, forkJoin, map, Observable, of, startWith, Subscription, switchMap } from 'rxjs';
import {
  BookingLMApiService,
  CustomerPlantModelExtLMApi,
  CustomerPlantConfigurationModelExtLMApi,
  GetPreBookingDataRequestLMApi,
  GetPreBookingDataResponseLMApi,
  PreBookingLMApiService,
  PreBookingJsonLMApi,
} from 'src/app/api';
import { PreBookingInformation } from 'src/app/dialogs/add-prebooking-dialog/add-prebooking-dialog.component';
import { BookingService } from 'src/app/services/booking.service';
import { ByPlantService } from 'src/app/services/by-plant.service';

interface IPreBookingInput {
  shipmentReference: string;
  pin: string;
}

@Component({
  selector: 'app-book-by-plant-prebooking',
  templateUrl: 'book-by-plant-prebooking.component.html',
})
export class BookByPlantPrebookingComponent {
  loadedPrebooking: boolean | undefined;
  plantId: string | null | undefined;
  forcePrebooking: number | undefined;
  shipmentReference: string = '';
  pin: string = '';
  prebookingsQuery: string | null = null;
  prebookingsInput: (IPreBookingInput | null)[] = [];
  error: boolean = false;
  selectedWarehouseData: CustomerPlantModelExtLMApi | undefined;
  warehouseConfiguration: CustomerPlantConfigurationModelExtLMApi | undefined;
  prebookingsList: PreBookingInformation[] | undefined = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public bookingService: BookingService,
    private apiService: BookingLMApiService,
    private preBookingApiService: PreBookingLMApiService,
    private byPlantService: ByPlantService,
    private modal: NgbModal
  ) {
    combineLatest([this.route.paramMap, this.route.queryParamMap])
      .pipe(
        switchMap(([paramMap, queryParamMap]) => {
          this.plantId = paramMap.get('plantId');
          let forcePrebookingString = queryParamMap.get('forceprebooking'); // 0 no 1 si
          this.prebookingsQuery = queryParamMap.get('prebookings');

          //GESTIONE LINGUA
          let localStorageLang = localStorage.getItem('lang');
          if (localStorageLang) this.bookingService.lang = localStorageLang;

          if (forcePrebookingString) {
            if (forcePrebookingString == '1') {
              this.forcePrebooking = 1;
            }
          }
          // if (prebookings) {
          //   const prebookingsArray = prebookings.split(';');
          //   const p = prebookingsArray.map((x) => {
          //     let pre = x.split('|');
          //     if (pre.length == 2) return { shipmentReference: pre[0], pin: pre[1] } as IPreBookingInput;
          //     else return null;
          //   });
          //   this.prebookingsInput = p;
          // }
          if (!this.plantId) {
            router.navigate(['/404']);
          }

          return this.byPlantService.verifyByPlant(this.plantId!);
        }),
        switchMap((_) => {
          //GESTIONE STEPPER
          let url = router.url.substring(1);
          if (this.prebookingsInput && this.prebookingsInput.length > 0)
            this.bookingService.setStepper([
              { label: '1', url: url + '/new', desktopPhase: 0 },
              { label: '2', url: url + '/new', desktopPhase: 1 },
              { label: '3', url: url + '/new/slot' },
              { label: 'END', url: '' },
            ]);
          else
            this.bookingService.setStepper([
              { label: '1', url: url },
              { label: '2', url: url + '/new', desktopPhase: 0 },
              { label: '3', url: url + '/new', desktopPhase: 1 },
              { label: '4', url: url + '/new/slot' },
              { label: 'END', url: '' },
            ]);

          this.bookingService.setStepperActive(0);
          this.bookingService.fromByPlant = true;
          this.bookingService.fromPrebooking = true;
          this.prebookingsList = this.bookingService.prebookingList;

          return this.apiService.getWarehouseByPlantId(this.plantId!);
        }),
        switchMap((x: CustomerPlantModelExtLMApi) => {
          this.selectedWarehouseData = x;
          if (this.selectedWarehouseData?.token) {
            return this.apiService.getWarehouseConfiguration(this.selectedWarehouseData?.token).pipe(
              map((x: CustomerPlantConfigurationModelExtLMApi) => {
                this.warehouseConfiguration = x;
                return true;
              })
            );
          } else {
            return of(false);
          }
        })
        // switchMap((x: boolean) => {
        //   if (x) {
        //     if (this.prebookingsInput && this.prebookingsInput.length > 0) {
        //       return combineLatest(this.prebookingsInput.filter((pb) => !!pb).map((pb) => pb && this.loadPrebooking(pb.shipmentReference, pb.pin))).pipe(
        //         map((lp) => {
        //           lp.map((l) => {
        //             if (!this.prebookingsList) this.prebookingsList = [];
        //             this.prebookingsList.push({ shipmentReference: this.shipmentReference, pin: this.pin, jsonData: l } as PreBookingInformation);
        //           });
        //           return true;
        //         })
        //       );
        //     } else return of(true);
        //   } else return of(false);
        // })
      )
      .subscribe((x: boolean) => {
        if (this.prebookingsQuery) {
          this.goNextFromPrebooking();
        }
        this.loadedPrebooking = true;
      });
  }

  loadPrebooking = (shipmentReference: string, pin: string): Observable<PreBookingJsonLMApi | null> => {
    this.error = false;
    let preBookingDataRequest: GetPreBookingDataRequestLMApi = {
      shipmentReference: shipmentReference,
      pin: pin,
    };

    return this.preBookingApiService.getPreBookingData(this.selectedWarehouseData?.token!, preBookingDataRequest).pipe(
      map((x: GetPreBookingDataResponseLMApi) => {
        if (x && x.jsonData) {
          return x.jsonData;
        } else {
          this.error = true;
          return null;
        }
      }),
      catchError((_) => {
        return of(null);
      })
    );
  };
  loadPrebookingClick = () => {
    this.loadPrebooking(this.shipmentReference, this.pin).subscribe((x) => {
      if (x) {
        if (!this.prebookingsList) this.prebookingsList = [];
        this.prebookingsList.push({ shipmentReference: this.shipmentReference, pin: this.pin, jsonData: x } as PreBookingInformation);
      }

      this.shipmentReference = '';
      this.pin = '';
    });
  };

  removePrebooking = (index: number) => {
    if (this.prebookingsList) this.prebookingsList.splice(index, 1);
    else this.prebookingsList = [];
    // this.prebookingsList = this.prebookingsList ? this.prebookingsList.splice(index, 1) : [];
  };

  goNextFromPrebooking = () => {
    this.bookingService.selectedWarehouseData = this.selectedWarehouseData;
    this.bookingService.warehouseConfiguration = this.warehouseConfiguration!;
    this.bookingService.checkConfiguration(this.warehouseConfiguration!);
    this.bookingService.plantCryptedId = this.plantId;
    this.bookingService.forcePrebooking = this.forcePrebooking;
    this.bookingService.prebookingList = this.prebookingsList;
    this.router.navigate(['new'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  };
}
