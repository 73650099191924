import { combineLatest } from "rxjs";
import { Component } from "@angular/core";
import { EditComponent } from "../edit.component";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ShipmentLMApiService, BookingLMApiService, ProductBookingLMApi, ActionTypeLMApi } from "src/app/api";
import { BookingService } from "src/app/services/booking.service";
import { TopBarService } from "src/app/services/topbar.service";
import { addHours, dateDiff, dateToUtcDate, getDateInTimezone, getMidnightUtc, SpreadObject } from "src/app/services/utils";
import { EditService } from "src/app/services/edit.service";

@Component({
  selector: "app-edit-by-plant",
  templateUrl: "../edit.component.html",
})
export class EditByPlantComponent extends EditComponent {
  constructor(
    router: Router,
    route: ActivatedRoute,
    bookingService: BookingService,
    shipmentApiService: ShipmentLMApiService,
    bookingApiService: BookingLMApiService,
    modal: NgbModal,
    topBarService: TopBarService,
    editService: EditService
  ) {
    super(router, route, bookingService, shipmentApiService, bookingApiService, modal, editService);
    combineLatest([route.queryParamMap, this.loaded$]).subscribe(([queryParamMap, loaded]) => {
      if (window.location !== window.parent.location) {
        topBarService.hideNavBar();
      }
      let editJsonInjectedString = queryParamMap.get("inject");
      if (editJsonInjectedString) {
        try {
          let dataInjected: DataInjected = <DataInjected>this.capitalizeInjectDataKeys(JSON.parse(editJsonInjectedString));
          if (dataInjected) {
            let d = new Date();
            let warehouseNowDate = getDateInTimezone(new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours()), this.configuration?.timezone ?? "UTC");
            let data = bookingService.data;
            if (dataInjected.R) data.referenceNumber = dataInjected.R;
            // TODO: Freeze e minDate
            if (dataInjected.P && dataInjected.F) {
              this.bookingService.minValidDate = addHours(warehouseNowDate, dataInjected.F) > getDateInTimezone(getMidnightUtc(new Date(dataInjected.P)), this.configuration?.timezone ?? "UTC") ?
                addHours(warehouseNowDate, dataInjected.F) :
                getDateInTimezone(getMidnightUtc(new Date(dataInjected.P)), this.configuration?.timezone ?? "UTC")
            } else {
              if (dataInjected.F) {

                this.bookingService.minValidDate = addHours(warehouseNowDate, dataInjected.F);
                // bookingService.checkSameSlotValid = false;
                bookingService.selectedSlot = null;
              }
              if (dataInjected.P) {
                this.bookingService.minValidDate = getDateInTimezone(getMidnightUtc(new Date(dataInjected.P)), this.configuration?.timezone ?? "UTC");
                bookingService.selectedSlot = null;
              }
            }
            if (dataInjected.N) data.notes = dataInjected.N;
            if (dataInjected.I && dataInjected.I.length > 0) {
              let productsInjected = dataInjected.I.map(
                (x: DataInjectedItem) =>
                ({
                  code: x.C,
                  quantity: x.Q,
                  deliveryMethod: x.T,
                  sourceDestination: x.D,
                  action: x.A == 1 ? ActionTypeLMApi.Load : x.A == 2 ? ActionTypeLMApi.Unload : undefined,
                } as ProductBookingLMApi)
              );
              //Da vecchia booking tutti gli items esistenti vengono svuotati e caricati quelli iniettati
              // if (data.products) {
              //   data.products.push(...productsInjected);
              // } else {
              data.products = productsInjected;
              // }
            }

            this.bookingService.isInjected = true;
            this.bookingService.data = data;
          }
        } catch (exception) {
          console.error("Error: " + exception);
        }
      }
    });
  }

  private capitalizeInjectDataKeys<T>(injectData: SpreadObject, isArray = false): T | Object[] {
    const spreadArray = (entry: SpreadObject): Object => {
      const dataEntry = Object.entries(entry);
      const uppercaseEntriesKeys = dataEntry.map(x => [
        x[0].toUpperCase(),
        Array.isArray(x[1]) ? this.capitalizeInjectDataKeys(<SpreadObject>x[1], true) : x[1]
      ]);
      return Object.fromEntries(uppercaseEntriesKeys);
    }
    if (isArray) {
      const returnArray = [];
      for (const data of <SpreadObject[]>injectData) {
        returnArray.push(spreadArray(data));
      }
      return returnArray;
    } else {
      return <T>spreadArray(injectData);
    }
  }
}

interface DataInjectedItem {
  /** Source/destination code*/
  D: string;
  /** Handling unit code (pallet, box, etc) */
  C: string;
  /**Quantity */
  Q: number;
  /** Trip type code*/
  T: string;
  /** Action type: Load => 1, Unload => 2 */
  A: number;
}
/**
 * Interfaccia per mappare dati contenenti handling units e reference number
 * */
interface DataInjected {
  /**Reference number */
  R: string;
  /** Freeze hours */
  F: number;
  /** Min date */
  P: string;
  /** Notes */
  N: string;
  /**Array of items */
  I: DataInjectedItem[];
}
