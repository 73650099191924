<form #dataForm="ngForm" style="height: 100%">
  <div class="main-container">
    <app-booking-stepper></app-booking-stepper>
    <app-title-with-languages [title]="'SLOT_EDIT'"></app-title-with-languages>
    <div class="d-flex flex-column align-self-center justify-content-start" style="max-width: 600px">
      <h5 class="text-center m-0">
        {{ "SLOT_INITIAL_DATE" | translate }}
        {{ bookingService.editInitialBookingDate | dateToPlantTimezone : "dd MMM yyyy - HH:mm" : configuration?.timezone | async | uppercase }}
      </h5>
      <h5 class="alert alert-danger text-center mt-0 mb-1 p-1" *ngIf="!bookingService.checkSameSlotValid">
        {{ "SLOT_INITIAL_NOT_VALID" | translate }}
      </h5>
      <label class="text-center m-0"
        *ngIf="selectedSlot?.from === initialSlot.from && bookingService.canSelectNewSlot">{{ "SLOT_HOW_TO_EDIT" |
        translate }}</label>
      <h5 class="alert alert-warning text-center m-0 p-1"
        *ngIf="selectedSlot && selectedSlot?.from !== initialSlot.from">
        {{ "SLOT_EDIT_NEW" | translate }} {{ selectedSlot.from | dateToPlantTimezone : "dd MMM yyyy - HH:mm" :
        configuration?.timezone | async | uppercase }}
      </h5>
    </div>

    <div *ngIf="!bookingService.canSelectNewSlot" class="inner-container d-flex flex-column overflow-y mt-2">
      <div class="slot-content mt-2">
        <div class="alert alert-danger d-flex flex-row text-break mx-auto w-100" style="max-width: 600px">
          <i class="bi bi-exclamation-triangle-fill me-3"></i>
          <div>
            <b>{{ "CANT_SELECT_NEW_SLOT" | translate }}</b>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="bookingService.canSelectNewSlot"
      class="d-flex justify-content-between align-items-center w-100 mt-3 mb-3 mx-auto" style="max-width: 600px">
      <a (click)="!prevDisabled && prevDay()">
        <svg version="1.1" [ngClass]="prevDisabled ? 'disabled' : ''" class="icon-arrow icon-arrow-left"
          xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000"
          enable-background="new 0 0 1000 1000" xml:space="preserve">
          <g>
            <path
              d="M825.3,500L454.6,129.3c-26.6-26.6-26.6-69.8,0-96.5c26.6-26.6,69.8-26.6,96.5,0L970,451.8c13.3,13.3,20,30.8,20,48.2c0,17.5-6.7,34.9-20,48.2L551.1,967.1c-26.6,26.6-69.8,26.6-96.5,0c-26.6-26.6-26.6-69.8,0-96.5L825.3,500z M30,870.7c-26.6,26.6-26.6,69.8,0,96.5c26.6,26.6,69.8,26.6,96.5,0l418.9-418.9c13.3-13.3,20-30.8,20-48.2c0-17.5-6.7-34.9-20-48.2L126.5,32.9C99.8,6.2,56.6,6.2,30,32.9c-26.6,26.6-26.6,69.8,0,96.5L400.6,500L30,870.7z" />
          </g>
        </svg>
      </a>
      <div class="d-flex flex-row justify-content-center">
        <h3 class="m-0 text-uppercase">
          <a (click)="openDateDialog()" class="cursor-pointer text-decoration-none"><i class="me-2 bi bi-calendar3"></i>
          </a>
          {{ currentDate | dateToPlantTimezone : "EEE dd MMM yyyy" : configuration?.timezone | async }}
          <a (click)="refresh()" class="cursor-pointer text-decoration-none"><i class="ms-2 bi bi-arrow-clockwise"></i>
          </a>
        </h3>
      </div>
      <a (click)="!nextDisabled && nextDay()">
        <svg version="1.1" [ngClass]="nextDisabled ? 'disabled' : ''" class="icon-arrow"
          xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000"
          enable-background="new 0 0 1000 1000" xml:space="preserve">
          <g>
            <path
              d="M825.3,500L454.6,129.3c-26.6-26.6-26.6-69.8,0-96.5c26.6-26.6,69.8-26.6,96.5,0L970,451.8c13.3,13.3,20,30.8,20,48.2c0,17.5-6.7,34.9-20,48.2L551.1,967.1c-26.6,26.6-69.8,26.6-96.5,0c-26.6-26.6-26.6-69.8,0-96.5L825.3,500z M30,870.7c-26.6,26.6-26.6,69.8,0,96.5c26.6,26.6,69.8,26.6,96.5,0l418.9-418.9c13.3-13.3,20-30.8,20-48.2c0-17.5-6.7-34.9-20-48.2L126.5,32.9C99.8,6.2,56.6,6.2,30,32.9c-26.6,26.6-26.6,69.8,0,96.5L400.6,500L30,870.7z" />
          </g>
        </svg>
      </a>
    </div>
    <div *ngIf="!loaded && bookingService.canSelectNewSlot"
      class="inner-container d-flex flex-column justify-content-start align-items-center overflow-y mt-2">
      <div class="spinner-border mb-3" style="width: 4rem; height: 4rem" role="status"></div>
      {{ "LOADING_DATA" | translate }}
    </div>
    <div *ngIf="loaded && bookingService.canSelectNewSlot"
      class="inner-container d-flex flex-column overflow-y mx-auto w-100" style="max-width: 600px">
      <div class="slot-content flex-grow-1">
        <a *ngFor="let slot of slots" class="card slot" [ngClass]="selectedSlot?.from === slot.from ? 'selected' : ''"
          (click)="selectSlot(slot)">
          <h5 class="m-0">{{ slot.from | dateToPlantTimezone : "HH:mm" : configuration?.timezone | async }}</h5>
        </a>
        <div class="alert alert-warning" role="alert" *ngIf="!slots || (slots && slots.length === 0)">
          <div>{{ "SLOT_NODATA" | translate }}</div>
        </div>
      </div>
    </div>
    <div class="inner-footer">
      <div class="alert alert-warning flex-shrink-1 mb-0 border border-1" role="alert" *ngIf="selectedSlot">
        <b>
          <div>{{ "SLOT_WARNING" | translate }} {{ "SLOT_WARNING2" | translate }}{{ selectedSlot.maxDelay }}{{
            "SLOT_WARNING3" | translate }}</div>
        </b>
      </div>
      <div class="alert alert-danger" role="alert" *ngIf="error">
        {{ error }}
      </div>
      <div class="dm-container">
        <button class="btn btn-primary footer-button" (click)="goBack()">
          <i class="bi bi-arrow-left"></i>
          <span>{{ "STEPPER_PREV" | translate }}</span>
        </button>
        <button class="btn btn-primary footer-button" (click)="goNext()" [disabled]="!selectedSlot">
          <i class="bi bi-arrow-right"></i>
          <span> {{ "STEPPER_NEXT" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>