import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  BookingLMApiService,
  BookingShipmentLMApi,
  CustomerPlantConfigurationModelExtLMApi,
  CustomerPlantModelExtLMApi,
  GetPreBookingDataRequestLMApi,
  GetPreBookingDataResponseLMApi,
  PreBookingLMApiService,
  ProductBookingLMApi,
} from '../api';
import { BookingService } from './booking.service';
import { TopBarService } from './topbar.service';
import { of, switchMap, forkJoin, catchError, BehaviorSubject, Observable, map, Subject } from 'rxjs';
import { PreBookingInformation } from '../dialogs/add-prebooking-dialog/add-prebooking-dialog.component';
import { getDateInTimezone, addHours } from './utils';

@Injectable({ providedIn: 'root' })
export class ByPlantService {
  public $updateConfiguration = new Subject<null>();
  constructor(
    private bookingService: BookingService,
    private apiService: BookingLMApiService,
    private preBookingApiService: PreBookingLMApiService,
    private topBarService: TopBarService
  ) {}

  verifyByPlant = (plantId: string): Observable<string | null> => {
    return this.apiService.getWarehouseByPlantId(plantId!).pipe(
      switchMap((x: CustomerPlantModelExtLMApi) => {
        this.bookingService.selectedWarehouseData = x;
        return x.token ? this.apiService.getWarehouseConfiguration(x?.token) : of(null);
      }),
      map((x: CustomerPlantConfigurationModelExtLMApi) => {
        if (x) {
          this.bookingService.warehouseConfiguration = x;
          x.customLogo && this.topBarService.icon.next(x.customLogo);
          x.customToolbarColor && this.topBarService.color.next(x.customToolbarColor);
          this.bookingService.checkConfiguration(x);
          return plantId;
        } else return null;
      }),
      catchError((e) => of(null))
    );
  };

  verifyPrebookings(prebookingsString: string | null, defaultFreezeValue: number | null | undefined) {
    if (!prebookingsString) {
      // GESTIRE POSTMESSAGE DAI PORTALI
      window.addEventListener('message', (event) => {
        console.log('POST MESSAGE RECEIVED: ', event);
        if (event.data !== 'Loaded') {
          try {
            const p = JSON.parse(event.data);
            let data: BookingShipmentLMApi = {
              referenceName: p.fatherShipment,
              referenceNumber: p.sonShipment,
              secReferenceNumber: p.external,
              sender: p.shipmentCreator ? p.shipmentCreator.email : '',
              receiver: p.receiver.name + ' ' + p.receiver.address,
              truckType: p.truckType,
              notes: p.notes,
              truckPlate: p.truckPlate,
              trailerPlate: p.trailerPlate,
              carrier: p.vector ? p.vector.sapCode : '',
              email: p.email,
            };

            if (p.items && p.items.length > 0) {
              data.products = p.items.map(
                (x: any) =>
                  ({
                    id: x.Id,
                    code: x.Code,
                    quantity: x.Value,
                    deliveryMethod: x.TripType,
                    sourceDestination: x.Destination,
                    action: x.Type,
                  } as ProductBookingLMApi)
              );
            }
            this.bookingService.data = data;

            this.bookingService.selectedDate = p.plannedCheckIn
              ? getDateInTimezone(new Date(p.plannedCheckIn as string), this.bookingService.warehouseConfiguration.timezone ?? 'UTC')
              : undefined;
            this.bookingService.maxValidDate = p.plannedCheckIn
              ? getDateInTimezone(new Date(p.lastValidTime as string), this.bookingService.warehouseConfiguration.timezone ?? 'UTC')
              : undefined;
            //Calcolo se presente freezingHours o firstValidDate
            let d = new Date();
            let warehouseNowDate = getDateInTimezone(
              new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours()),
              this.bookingService.warehouseConfiguration.timezone ?? 'UTC'
            );
            console.log('FREEZE INJECTED', p.freezingHours);
            console.log('FREEZE default', defaultFreezeValue);
            let freeze = p.freezingHours != null && p.freezingHours != undefined ? parseInt(p.freezingHours) : defaultFreezeValue;
            let nowPlusFreeze = addHours(warehouseNowDate, freeze ?? 0);
            let minDate = p.firstValidTime ? getDateInTimezone(new Date(p.firstValidTime as string), this.bookingService.warehouseConfiguration.timezone ?? 'UTC') : null;
            this.bookingService.minValidDate =
              minDate && freeze ? (nowPlusFreeze > minDate ? nowPlusFreeze : minDate) : freeze && !minDate ? nowPlusFreeze : !freeze && minDate ? minDate : undefined;
            console.log('by plant service min: ', this.bookingService.minValidDate);
            this.bookingService.prebookingMinDate = this.bookingService.minValidDate;
            this.bookingService.prebookingMaxDate = this.bookingService.maxValidDate;
            this.$updateConfiguration.next(null);
          } catch (e) {
            console.error('Exception: ' + e);
          }
        }
      });

      // INVIO MESSAGGIO POST
      window.parent.postMessage('Loaded', '*');
    } else {
      // GESTIRE I PREBOOKINGS PASSATI IN QUERY PARAMS
      let prebookingsList = prebookingsString.split(';')?.map((p) => {
        let split = p.split('|');
        return { shipmentReference: split[0], pin: split[1] } as GetPreBookingDataRequestLMApi;
      });
      // PRE OGNI PREBOOKING RECUPERO I DATI DAL SERVER E IN ORDINE CHIAMO APPLYSINGLEPREBOOKING
      of(prebookingsList)
        .pipe(
          switchMap((list) =>
            forkJoin(
              list.map((p) =>
                this.preBookingApiService.getPreBookingData(this.bookingService.selectedWarehouseData?.token!, p).pipe(
                  catchError((_) => {
                    return of(null);
                  })
                )
              )
            )
          )
        )
        .subscribe((prebookingDataResponse: GetPreBookingDataResponseLMApi[]) => {
          if (prebookingDataResponse) {
            let list = prebookingDataResponse
              .filter((r) => r && r.jsonData)
              .map((r, i) => {
                let p = prebookingsList[i];
                return { shipmentReference: p.shipmentReference, pin: p.pin, jsonData: r.jsonData } as PreBookingInformation;
              });
            this.bookingService.preBookingInformationList.next(list);
          }
        });
    }
  }
}
