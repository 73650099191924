import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BookComponent } from './pages/book/book/book.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BaseNewShipmentComponent } from './pages/book/base-new-shipment.component';
import { LeafletInfoDialogComponent } from './dialogs/leaflet-info-dialog/leaflet-info-dialog.component';
import { NgbDropdownModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BookSlotComponent } from './pages/book/book-slot/book-slot.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddProductDialogComponent } from './dialogs/add-product-dialog/add-product-dialog.component';
import { EditProductDialogComponent } from './dialogs/edit-product-dialog/edit-product-dialog.component';
import { ViewComponent } from './pages/view/view.component';
import { FileShowerComponent } from './components/file-shower/file-shower.component';
import { EditComponent } from './pages/edit/edit.component';
import { EditSlotComponent } from './pages/edit/edit-slot/edit-slot.component';
import { ImgBase64Component } from './components/img-base64/img-base64.component';
import { TranslateLabelPipe } from './pipes/translate-label.pipe';
import { LMApiApiModule, LMApiConfiguration } from './api';
import { environment } from 'src/environments/environment';
import { NgbDateUtcAdapter } from './services/ngb-date-adapter';
import { DateToPlantTimezonePipe } from './pipes/date-to-plant-timezone.pipe';
import { GetNameFromCodePipe } from './pipes/get-name-from-code.pipe';
import { registerLocaleData } from '@angular/common';
import { MyBookingsComponent } from './pages/my-bookings/my-bookings.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { NavigationService } from './services/navigation.service';
import { CheckEmailConfirmationComponent } from './dialogs/check-email-confirmation/check-email-confirmation.component';
import { DeleteBookingDialogComponent } from './dialogs/delete-booking-dialog/delete-booking-dialog.component';
import { AddPreBookingDialogComponent } from './dialogs/add-prebooking-dialog/add-prebooking-dialog.component';
import { TitleWithLanguagesComponent } from './components/title-with-languages/title-with-languages.component';
import { AddProductComponent } from './components/add-product/add-product.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { EditByPlantComponent } from './pages/edit/edit-by-plant/edit-by-plant.component';
/* import { BookByPlantComponent } from "./pages/book/book-by-plant/book-by-plant.component"; */
import { BookByPlantPrebookingComponent } from './pages/book/book-by-plant-prebooking/book-by-plant-prebooking.component';
import { EditAddReasonComponent } from './pages/edit/edit-add-reason/edit-add-reason.component';
import { Page404Component } from './pages/404/404.component';
import { Page404ShipmentComponent } from './pages/404/404-shipment.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { BookByCustomerComponent } from './pages/book/book-by-customer/book-by-customer.component';
import { TopBarService } from './services/topbar.service';
import { ErrorMessageDialogComponent } from './dialogs/error-message-dialog/error-message-dialog.component';
import { PrivacyInitialDialogComponent } from './dialogs/privacy-initial-dialog/privacy-initial-dialog.component';
import { PrivacyCompanyDialogComponent } from './dialogs/privacy-company-dialog/privacy-company-dialog.component';
import { BookingStepperComponent } from './components/stepper/booking-stepper.component';
import { SlotDateSelectionDialogComponent } from './dialogs/slot-date-selection-dialog/slot-date-selection-dialog.component';

import localeEN from '@angular/common/locales/en';
import localeIT from '@angular/common/locales/it';
import localePL from '@angular/common/locales/pl';
import localeRO from '@angular/common/locales/ro';
import localeRU from '@angular/common/locales/ru';
import localeFR from '@angular/common/locales/fr';
import localeDE from '@angular/common/locales/de';
import localeES from '@angular/common/locales/es';
import localeTR from "@angular/common/locales/tr";
import { DeletedComponent } from './pages/deleted/deleted.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { PrebookingChooseEmailComponent } from './dialogs/prebooking-choose-email/prebooking-choose-email.component';
registerLocaleData(localeEN);
registerLocaleData(localeIT);
registerLocaleData(localePL);
registerLocaleData(localeRO);
registerLocaleData(localeRU);
registerLocaleData(localeFR);
registerLocaleData(localeDE);
registerLocaleData(localeES);
registerLocaleData(localeTR);

@NgModule({
  declarations: [
    AppComponent,
    LeafletInfoDialogComponent,
    FileShowerComponent,
    ImgBase64Component,
    QrCodeComponent,
    CheckEmailConfirmationComponent,
    TitleWithLanguagesComponent,
    NavMenuComponent,
    AddProductDialogComponent,
    EditProductDialogComponent,
    DeleteBookingDialogComponent,
    AddPreBookingDialogComponent,
    AddProductComponent,
    TranslateLabelPipe,
    SafeHtmlPipe,
    DateToPlantTimezonePipe,
    GetNameFromCodePipe,
    Page404Component,
    Page404ShipmentComponent,
    BookComponent,
    BaseNewShipmentComponent,
    BookSlotComponent,
    /* BookByPlantComponent, */
    BookByPlantPrebookingComponent,
    BookByCustomerComponent,
    EditComponent,
    EditSlotComponent,
    EditAddReasonComponent,
    EditByPlantComponent,
    MyBookingsComponent,
    ViewComponent,
    ErrorMessageDialogComponent,
    PrivacyInitialDialogComponent,
    PrivacyCompanyDialogComponent,
    BookingStepperComponent,
    SlotDateSelectionDialogComponent,
    DeletedComponent,
    PrebookingChooseEmailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LMApiApiModule.forRoot(
      () =>
        new LMApiConfiguration({
          basePath: environment.baseApiUrl,
          withCredentials: false,
        })
    ),
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LeafletModule,
    LeafletMarkerClusterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    NgbTooltipModule,
    NgbDropdownModule,
    RecaptchaV3Module,
  ],
  providers: [
    NgbDateUtcAdapter,
    DateToPlantTimezonePipe,
    GetNameFromCodePipe,
    NavigationService,
    TopBarService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcTc3YUAAAAAIeHcdudl7QXBr42YTY56eXivR6m' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
